<template>
  <div class="list-wrapper" v-if="orderList.length > 0">
    <div class="item" v-for="(item, key) in orderList" :key="key" @click="toDetail(item)">
      <div class="title">
        <span class="order-id">
          <img src="@/assets/order.png" />
          <span>保单号：{{ item.policyNo }}</span>
        </span>
      </div>
      <div class="content">
        <p class="product-name">
          {{ item.productName }}
        </p>
        <p class="time">生效时间：{{ item.startTime }} {{item.startHour}}时</p>
        <p class="staff-info">
          <span class="policy-holder">投保人：{{ item.appliName }}</span>
          <span class="insured">被保人：{{ item.insuredName }}</span>
        </p>
        <p class="price">
          <span class="price-title">保费</span><span class="mark">¥</span
          ><span class="price-num">{{ item.price }}</span>
        </p>
        <p class="logo">
          <img
            v-if="item.policyStatusName == '未生效'"
            src="@/assets/invalid.png"
            alt=""
          />
          <img
            v-else-if="item.policyStatusName == '已退保'"
            src="@/assets/cancel.png"
            alt=""
          />
          <img v-else src="@/assets/underwrite.png" alt="" />
        </p>
        <!-- <p class="cancel-btn" v-if="item.policyStatusName == '保障中'"  @click.stop="endOrder(item)">立即退保</p> -->
      </div>
    </div>
  </div>
  <div class="no-data" v-else>
    <div class="img-box">
      <img src="@/assets/no-data.png" alt="" />
      <p class="no-title">暂无保单~</p>
    </div>
  </div>
</template>
<script>
import { getOrderList, calculate,getOpenId } from "@/utils/api.js";
import moment from "moment";
export default {
  data() {
    return {
      orderList: [],
      endHour:''
    };
  },
  async mounted() {
    console.log(this.$route.query.code)
    if(this.$route.query.code){
      let params = {
        code: this.$route.query.code,
        channelId: "wx2f80f772533e490f",
      };
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getOpenId(params).then((res) => {
        if (res.success) {
          sessionStorage.setItem("orderNo", this.$route.query.orderNo);
          sessionStorage.setItem("openId", res.result.openId);
          this.getList();
        } else {
          console.log('elseToOrder')
          this.$router.replace({
            path: "/orderList",
          });
        }
      });
    }
    if(sessionStorage.getItem('openId') == null || sessionStorage.getItem('openId') == 'null'){
      this.wxLoad();
      this.$toast.clear();
      console.log('tag1')
    } else {
      this.getList();
      console.log('tag2')
    }
    this.$toast.clear();
  },
  async activated() {
    this.$toast.clear();
  },
  methods: {
    // 静默授权
    wxLoad(){
      let appId = "wx2f80f772533e490f";
      let local = window.location.href;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appId +
        "&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=1#wechat_redirect";
    },
    // 查询保单列表
    getList() {
      // openId: "121211212112121122121"
      getOrderList({ openId: sessionStorage.getItem("openId") }).then((res) => {
        console.log(res);
        if (res.success) {
          this.orderList = res.result;
        } else {
          this.$toast("查询保单失败");
        }
      });
    },
    // 立即退保
    endOrder(item) {
      let myDate = new Date();
      var hours = myDate.getHours(); //获取当前小时数(0-23)
      let minutes = myDate.getMinutes(); //获取当前分钟数(0-59)
      let parmas = {
        cancelInfos: [
          {
            policyNo: item.policyNo,
            validDate: moment().format("YYYY-MM-DD"),
          }
        ]
      }
      // 如果当前分钟添加半小时后大于下个整点，那么传参小时应该为下下个整点
      if (minutes + 30 > 60) {
        this.endHour = myDate.getHours()+2;
        parmas.cancelInfos[0].validHour = this.endHour.toString();
      } else {
        this.endHour= myDate.getHours()+1;
        parmas.cancelInfos[0].validHour = this.endHour.toString();
      }
      
      calculate(parmas).then((res) => {
        if (res.success) {
          let resData = res.result;
          let returnFee = resData.cancelResInfos[0].returnFee; // 试算接口返回的退费金额
          let endDate = moment().format("YYYY-MM-DD ")+  this.endHour.toString();
          this.$router.push({
            path: "/surrender",
            query: {
              id: item.id,
            },
          });
          let data = {
            returnFee:returnFee,
            policyNo:item.policyNo,
            price:item.price,
            startTime:item.startTime+' 0',
            endTime:endDate,
            endDate:moment().format("YYYY-MM-DD"),
            endHour:this.endHour
          }
          sessionStorage.setItem('sendData',JSON.stringify(data))
        } else {
          this.$toast(res.message);
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        path: "/policyDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.list-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  padding: 10px;
  .item {
    width: 100%;
    height: 143px;
    background: #fff;
    border-radius: 8px;
    padding: 12px 10px 14px 10px;
    position: relative;
    margin-bottom: 10px;
    .title {
      position: relative;
      .order-id {
        img {
          width: 11px;
          height: 12px;
          display: inline-block;
          margin-right: 6px;
        }
        span {
          font-size: 13px;
          color: #333;
        }
      }
    }
    .content {
      margin-top: 8px;
      .product-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        height: 23px;
        line-height: 23px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time {
        height: 19px;
        line-height: 19px;
        margin-top: 4px;
        color: #999999;
        font-size: 13px;
      }
      .staff-info {
        height: 19px;
        line-height: 19px;
        margin-top: 8px;
        color: #666666;
        font-size: 13px;
        .policy-holder {
          margin-right: 26px;
        }
      }
      .price {
        height: 17px;
        line-height: 17px;
        color: #333333;
        font-size: 12px;
        margin-top: 8px;
        .price-title {
          font-weight: bold;
          margin-right: 4px;
        }
        .mark,
        .price-num {
          color: #ff7418;
        }
        .mark {
          font-size: 10px;
        }
        .price-num {
          font-size: 15px;
        }
      }
      .logo {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 66px;
        height: 53px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .cancel-btn {
        position: absolute;
        right: 16px;
        bottom: 5px;
        width: 69px;
        height: 23px;
        line-height: 23px;
        border-radius: 4px;
        border: 1px solid #999999;
        text-align: center;
        font-size: 13px;
        color: #999999;
      }
    }
  }
}
.no-data {
  //   display: flex;
  //   display: -webkit-flex;
  //   align-items:center;
  //   justify-content:center;
  .img-box {
    width: 224px;
    height: 173px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -131px;
    margin-left: -112px;
    img {
      width: 100%;
      height: 100%;
    }
    .no-title {
      height: 21px;
      line-height: 21px;
      font-size: 15px;
      color: #c2c2c2;
      text-align: center;
    }
  }
}
</style>


